@font-face{
    font-family: 'Source Code Variable';
    font-weight: 200 900;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
    src: url('https://font.cdn.yer.tools/SourceCodePro/WOFF2/VAR/SourceCodeVariable-Roman.ttf.woff2') format('woff2'),
         url('https://font.cdn.yer.tools/SourceCodePro/WOFF/VAR/SourceCodeVariable-Roman.ttf.woff') format('woff'),
         url('https://font.cdn.yer.tools/SourceCodePro/VAR/SourceCodeVariable-Roman.ttf') format('truetype');
}

@font-face{
    font-family: 'Source Code Variable';
    font-weight: 200 900;
    font-style: italic;
    font-stretch: normal;
    font-display: swap;
    src: url('https://font.cdn.yer.tools/SourceCodePro/WOFF2/VAR/SourceCodeVariable-Italic.ttf.woff2') format('woff2'),
         url('https://font.cdn.yer.tools/SourceCodePro/WOFF/VAR/SourceCodeVariable-Italic.ttf.woff') format('woff'),
         url('https://font.cdn.yer.tools/SourceCodePro/VAR/SourceCodeVariable-Italic.ttf') format('truetype');
}
