.uix.widget.site-content{
    box-sizing: border-box;
    width: 100%;
    min-width: unset;
    max-width: $widget-site-content-max-width;

    padding: 6px;
    padding-bottom: 50px;

    margin-left: auto;
    margin-right: auto;
}