div.uix-markdown{
    word-wrap: break-word;

    code{
        font-size: .9em;
        padding: .05em .1em;
        border: .1em solid $markdown-code-background-color;

        border-radius: .4em;

        color: $markdown-code-text-color;
        background-color: $markdown-code-background-color;

        transition: color ease-in-out $markdown-timing-hover, background-color ease-in-out $markdown-timing-hover;

        &:hover{
            color: $markdown-code-text-color-hover;
            background-color: transparent;
        }
    }

}