div.uix-markdown{
    h1{
        font-size: 2.5em;
        font-weight: 250;
    }

    h2{
        font-size: 1.75em;
        font-weight: 350;
    }

    h1, h2{
        text-decoration: underline;
        text-decoration-color: $markdown-underline-color;

        transition: text-decoration-color ease-in-out $markdown-timing-hover;
        &:hover{
            text-decoration-color: $markdown-underline-color-hover;
        }
    }

    h3{
        font-size: 1.25em;
        font-weight: 450;
    }
}