body{
    position: relative;

    min-height: 100vh;
    margin: 0;


    font-size: $page-font-size;
}

body, input, textarea{
    font-family: $page-font-family;
    font-weight: $page-font-weight;
    color: $page-dark-color;
}

code, .monospace{
    font-family: $page-font-family-monospace;
    font-weight: $page-code-font-weight;
}

a{
    color: $page-dark-color;
}

b{
    font-weight: $page-bold-font-weight;
}

hr{
    height: .2em;
    border: none;
    
    background-color: $page-gray-color;
    opacity: .33;

    transition: background-color ease-in-out $page-timing-hover;
    &:hover{
        background-color: $page-gray-color-hover;
    }
}

u{
    text-decoration-color: $page-gray-color;

    transition: text-decoration-color ease-in-out $page-timing-hover;
    &:hover{
        text-decoration-color: $page-gray-color-hover;
    }
}