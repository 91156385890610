body.UIX-Edit-Mode .uix.widget.uix-webpage-editor{
    position: absolute;
    height: 100vh;
    width: 100vw;

    overflow: hidden;
    
    background-color: $widget-editor-background-color;

    .uix.widget.vertical-divider{
        &>.uix.wrapper{
            overflow: auto;
            height: 100vh;
        }
    }

    .uix.wrapper.webpage-wrapper{
        outline: 2px solid $widget-editor-webpage-wrapper-outline-color;

        .uix.widget.uix-webpage{
            position: relative;

            .uix.widget{
                cursor: pointer;
    
                &.hover-highlight{
                    outline: 2px solid $widget-editor-widget-hover-outline-color;

                    &::before{
                        content: "";

                        position: absolute;
                        left: 0;
                        top: 0;

                        width: 100%;
                        height: 100%;
                        
                        opacity: .25;
                        background-color: $widget-editor-widget-hover-background-color;
                    }
                }
            }
        }
        
    }
}