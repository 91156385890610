*{
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-indicator-color $scrollbar-background-color;

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;

        background-color: $scrollbar-background-color;
    }

    &::-webkit-scrollbar-track {
        background-color: $scrollbar-background-color;

        transition: background ease-in-out $page-timing-hover;
        &:hover{
            background-color: $scrollbar-hover-background-color;
        }
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-indicator-color;
        border-radius: 10px;
        border: 2px solid $scrollbar-background-color;

        transition: background-color ease-in-out $page-timing-hover, border ease-in-out $page-timing-hover;
        &:hover{
            background-color: $scrollbar-hover-indicator-color;
            border: 2px solid $scrollbar-hover-background-color;
        }
    }
}

::selection, input::selection, textarea::selection{
    background-color: $page-highlight-color;
}