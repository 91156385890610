div.uix-markdown{
    span.checkbox{
        box-sizing: border-box;
        display: inline-block;

        height: 1em;
        width: 1em;
        margin-bottom: -.15em;

        border: .12em solid $markdown-checkbox-color;
        border-radius: .25em;

        transition: border-color ease-in-out $markdown-timing-hover, background-color ease-in-out $markdown-timing-hover;

        &::before, &::after{
            content: "";
            position: absolute;

            width: 0;
            height: 0;

            border-radius: .1em;

            transition: background-color ease-in-out $markdown-timing-hover;
        }

        &.checked{
            background-color: $markdown-checkbox-color;

            &::before, &::after{
                background-color: $markdown-checkbox-hook-color;
            }

            &::before{
                width: .12em;
                height: .35em;

                margin-left: .15em;
                margin-top: .32em;

                transform: rotate(-50deg);
            }

            &::after{
                width: .12em;
                height: .65em;

                margin-left: .42em;
                margin-top: .05em;

                transform: rotate(-140deg);
            }
        }

        &.plus{
            border-color: $markdown-checkbox-plus-background-color;
            background-color: $markdown-checkbox-plus-background-color;

            &::before, &::after{
                background-color: $markdown-checkbox-plus-color;
            }

            &::before{
                width: .7em;
                height: .12em;

                margin-left: .03em;
                margin-top: .32em;
            }

            &::after{
                width: .12em;
                height: .7em;

                margin-left: .32em;
                margin-top: .03em;
            }
        }

        &.minus{
            border-color: $markdown-checkbox-minus-background-color;
            background-color: $markdown-checkbox-minus-background-color;

            &::before{
                width: .7em;
                height: .12em;

                margin-left: .03em;
                margin-top: .32em;

                background-color: $markdown-checkbox-minus-color;
            }
        }

        &:hover{
            border-color: $markdown-checkbox-color-hover;

            &.checked{
                background-color: $markdown-checkbox-color-hover;

                &::before, &::after{
                    background-color: $markdown-checkbox-hook-color-hover;
                }
            }

            &.plus{
                border-color: $markdown-checkbox-plus-background-color-hover;
                background-color: $markdown-checkbox-plus-background-color-hover;

                &::before, &::after{
                    background-color: $markdown-checkbox-plus-color-hover;
                }
            }

            &.minus{
                border-color: $markdown-checkbox-minus-background-color-hover;
                background-color: $markdown-checkbox-minus-background-color-hover;
    
                &::before{
                    background-color: $markdown-checkbox-minus-color-hover;
                }
            }
        }
    }
}