$widget-button-color: $page-light-color;
$widget-button-background-color: $page-dark-color;

$widget-button-font-size: .85em;
$widget-button-font-weight: 550;

$widget-popup-background-color: rgba(0,0,0,.3);
$widget-popup-wrapper-background-color: $page-white-color;

$widget-site-content-max-width: $page-site-content-max-width;

$widget-webpage-background-color: $page-light-color;

$widget-timing-hover: $page-timing-hover;