@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("https://font.cdn.yer.tools/SourceSansPro/WOFF2/VAR/SourceSansVariable-Roman.ttf.woff2") format("woff2"), url("https://font.cdn.yer.tools/SourceSansPro/WOFF/VAR/SourceSansVariable-Roman.ttf.woff") format("woff"), url("https://font.cdn.yer.tools/SourceSansPro/VAR/SourceSansVariable-Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Source Sans Variable";
  font-weight: 200 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url("https://font.cdn.yer.tools/SourceSansPro/WOFF2/VAR/SourceSansVariable-Italic.ttf.woff2") format("woff2"), url("https://font.cdn.yer.tools/SourceSansPro/WOFF/VAR/SourceSansVariable-Italic.ttf.woff") format("woff"), url("https://font.cdn.yer.tools/SourceSansPro/VAR/SourceSansVariable-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Code Variable";
  font-weight: 200 900;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("https://font.cdn.yer.tools/SourceCodePro/WOFF2/VAR/SourceCodeVariable-Roman.ttf.woff2") format("woff2"), url("https://font.cdn.yer.tools/SourceCodePro/WOFF/VAR/SourceCodeVariable-Roman.ttf.woff") format("woff"), url("https://font.cdn.yer.tools/SourceCodePro/VAR/SourceCodeVariable-Roman.ttf") format("truetype");
}
@font-face {
  font-family: "Source Code Variable";
  font-weight: 200 900;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
  src: url("https://font.cdn.yer.tools/SourceCodePro/WOFF2/VAR/SourceCodeVariable-Italic.ttf.woff2") format("woff2"), url("https://font.cdn.yer.tools/SourceCodePro/WOFF/VAR/SourceCodeVariable-Italic.ttf.woff") format("woff"), url("https://font.cdn.yer.tools/SourceCodePro/VAR/SourceCodeVariable-Italic.ttf") format("truetype");
}
.uix-text-color-accent {
  color: #4083ca;
}
.uix-text-color-accent.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-accent.hover:hover {
  color: #206fc2;
}

.uix-text-color-error {
  color: #f34e4e;
}
.uix-text-color-error.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-error.hover:hover {
  color: #f51c1c;
}

.uix-text-color-warning {
  color: #e1f34e;
}
.uix-text-color-warning.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-warning.hover:hover {
  color: #ddf51c;
}

.uix-text-color-ready {
  color: #60f34e;
}
.uix-text-color-ready.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-ready.hover:hover {
  color: #33f51c;
}

.uix-text-color-info {
  color: #4e9ff3;
}
.uix-text-color-info.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-info.hover:hover {
  color: #0c7ef5;
}

.uix-text-color-code {
  color: #0c44c1;
}
.uix-text-color-code.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-code.hover:hover {
  color: #052b80;
}

.uix-text-color-highlight {
  color: #2ec5ff;
}
.uix-text-color-highlight.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-highlight.hover:hover {
  color: #62d4ff;
}

.uix-text-color-dark {
  color: #252627;
}
.uix-text-color-dark.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-dark.hover:hover {
  color: #1c1d1d;
}

.uix-text-color-light {
  color: #eaecef;
}
.uix-text-color-light.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-light.hover:hover {
  color: white;
}

.uix-text-color-gray {
  color: #848688;
}
.uix-text-color-gray.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-gray.hover:hover {
  color: #a3a4a6;
}

.uix-text-color-black {
  color: #000000;
}
.uix-text-color-black.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-black.hover:hover {
  color: black;
}

.uix-text-color-white {
  color: #ffffff;
}
.uix-text-color-white.hover {
  transition: color ease-in-out 150ms;
}
.uix-text-color-white.hover:hover {
  color: white;
}

.uix-solid-bg-accent {
  background-color: #4083ca;
}
.uix-solid-bg-accent.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-accent.hover:hover {
  background-color: #206fc2;
}

.uix-solid-bg-error {
  background-color: #f34e4e;
}
.uix-solid-bg-error.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-error.hover:hover {
  background-color: #f51c1c;
}

.uix-solid-bg-warning {
  background-color: #e1f34e;
}
.uix-solid-bg-warning.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-warning.hover:hover {
  background-color: #ddf51c;
}

.uix-solid-bg-ready {
  background-color: #60f34e;
}
.uix-solid-bg-ready.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-ready.hover:hover {
  background-color: #33f51c;
}

.uix-solid-bg-info {
  background-color: #4e9ff3;
}
.uix-solid-bg-info.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-info.hover:hover {
  background-color: #0c7ef5;
}

.uix-solid-bg-code {
  background-color: #0c44c1;
}
.uix-solid-bg-code.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-code.hover:hover {
  background-color: #052b80;
}

.uix-solid-bg-highlight {
  background-color: #2ec5ff;
}
.uix-solid-bg-highlight.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-highlight.hover:hover {
  background-color: #62d4ff;
}

.uix-solid-bg-dark {
  background-color: #252627;
}
.uix-solid-bg-dark.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-dark.hover:hover {
  background-color: #1c1d1d;
}

.uix-solid-bg-light {
  background-color: #eaecef;
}
.uix-solid-bg-light.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-light.hover:hover {
  background-color: white;
}

.uix-solid-bg-gray {
  background-color: #848688;
}
.uix-solid-bg-gray.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-gray.hover:hover {
  background-color: #a3a4a6;
}

.uix-solid-bg-black {
  background-color: #000000;
}
.uix-solid-bg-black.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-black.hover:hover {
  background-color: black;
}

.uix-solid-bg-white {
  background-color: #ffffff;
}
.uix-solid-bg-white.hover {
  transition: background-color ease-in-out 150ms;
}
.uix-solid-bg-white.hover:hover {
  background-color: white;
}

.uix-bg-text-accent {
  background-color: #4083ca;
  color: #000000;
}
.uix-bg-text-accent.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-accent.hover:hover {
  background-color: #206fc2;
  color: white;
}

.uix-bg-text-error {
  background-color: #f34e4e;
  color: #000000;
}
.uix-bg-text-error.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-error.hover:hover {
  background-color: #f51c1c;
  color: black;
}

.uix-bg-text-warning {
  background-color: #e1f34e;
  color: #252627;
}
.uix-bg-text-warning.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-warning.hover:hover {
  background-color: #ddf51c;
  color: #1c1d1d;
}

.uix-bg-text-ready {
  background-color: #60f34e;
  color: #252627;
}
.uix-bg-text-ready.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-ready.hover:hover {
  background-color: #33f51c;
  color: #1c1d1d;
}

.uix-bg-text-info {
  background-color: #4e9ff3;
  color: #252627;
}
.uix-bg-text-info.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-info.hover:hover {
  background-color: #0c7ef5;
  color: #1c1d1d;
}

.uix-bg-text-code {
  background-color: #0c44c1;
  color: #eaecef;
}
.uix-bg-text-code.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-code.hover:hover {
  background-color: #052b80;
  color: white;
}

.uix-bg-text-highlight {
  background-color: #2ec5ff;
  color: #252627;
}
.uix-bg-text-highlight.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-highlight.hover:hover {
  background-color: #62d4ff;
  color: #1c1d1d;
}

.uix-bg-text-dark {
  background-color: #252627;
  color: #eaecef;
}
.uix-bg-text-dark.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-dark.hover:hover {
  background-color: #1c1d1d;
  color: white;
}

.uix-bg-text-light {
  background-color: #eaecef;
  color: #252627;
}
.uix-bg-text-light.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-light.hover:hover {
  background-color: white;
  color: #1c1d1d;
}

.uix-bg-text-gray {
  background-color: #848688;
  color: #000000;
}
.uix-bg-text-gray.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-gray.hover:hover {
  background-color: #a3a4a6;
  color: #1c1d1d;
}

.uix-bg-text-black {
  background-color: #000000;
  color: #eaecef;
}
.uix-bg-text-black.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-black.hover:hover {
  background-color: black;
  color: white;
}

.uix-bg-text-white {
  background-color: #ffffff;
  color: #252627;
}
.uix-bg-text-white.hover {
  transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
}
.uix-bg-text-white.hover:hover {
  background-color: white;
  color: #1c1d1d;
}

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  font-size: 18px;
}

body, input, textarea {
  font-family: "Source Sans Variable", Segoe UI, sans-serif;
  font-weight: 350;
  color: #252627;
}

code, .monospace {
  font-family: "Source Code Variable", Consolas, monospace;
  font-weight: 400;
}

a {
  color: #252627;
}

b {
  font-weight: 550;
}

hr {
  height: 0.2em;
  border: none;
  background-color: #848688;
  opacity: 0.33;
  transition: background-color ease-in-out 150ms;
}
hr:hover {
  background-color: #a3a4a6;
}

u {
  text-decoration-color: #848688;
  transition: text-decoration-color ease-in-out 150ms;
}
u:hover {
  text-decoration-color: #a3a4a6;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #aaaaaa #dadada;
}
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: #dadada;
}
*::-webkit-scrollbar-track {
  background-color: #dadada;
  transition: background ease-in-out 150ms;
}
*::-webkit-scrollbar-track:hover {
  background-color: #cacaca;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 10px;
  border: 2px solid #dadada;
  transition: background-color ease-in-out 150ms, border ease-in-out 150ms;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #888888;
  border: 2px solid #cacaca;
}

::selection, input::selection, textarea::selection {
  background-color: #2ec5ff;
}

@keyframes animation-fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes animation-fade-out-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes animation-loading-background {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 50px;
  }
}
.uix.context-menu.wrapper {
  font-size: 16px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  user-select: none;
}
.uix.context-menu.wrapper .uix.context-menu.container {
  position: fixed;
  overflow: auto;
  padding: 6px 0;
  box-sizing: border-box;
  color: #eaecef;
  border: 1px solid #000000;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 #000000;
  background-color: #252627;
  transition: opacity ease-in-out 150ms;
}
.uix.context-menu.wrapper .uix.context-menu.container table {
  width: 100%;
  margin: 0;
  border-collapse: collapse;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row {
  vertical-align: middle;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable {
  cursor: pointer;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable:hover {
  background-color: #1c1d1d;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  width: 20px;
  height: 20px;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  width: 20px;
  height: 20px;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::after {
  content: "";
  position: absolute;
  display: inline-block;
  transform-origin: center center;
  left: 9px;
  top: 4px;
  width: 2px;
  height: 12px;
  border-radius: 1px;
  background-color: #b5b6bf;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more:hover::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more:hover::after {
  background-color: #ffffff;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable:hover td.uix.context-menu.item-more::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable:hover td.uix.context-menu.item-more::after {
  background-color: #ffffff;
}

.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::after {
  transform-origin: top center;
  left: 11.3159312291px;
  transition: background-color ease-in-out 150ms, left ease-in-out 150ms;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::before {
  top: 10.7071067812px;
  transform: rotate(135deg);
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more::after {
  top: 9.2928932188px;
  transform: rotate(45deg);
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more:hover::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable td.uix.context-menu.item-more:hover::after {
  left: 17.1715728753px;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable:hover td.uix.context-menu.item-more::before, .uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.clickable:hover td.uix.context-menu.item-more::after {
  left: 17.1715728753px;
}

.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.placeholder {
  text-align: center;
  text-decoration: underline;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row.greyed-out {
  cursor: not-allowed;
  color: #848688;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row td.uix.context-menu.item-name {
  padding: 3px 6px;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row td.uix.context-menu.item-empty {
  padding: 13px 0;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.item-row td.uix.context-menu.empty-icon {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  width: 20px;
  height: 20px;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.group-spacer td {
  padding-top: 1px;
  background-color: #1c1d1d;
}
.uix.context-menu.wrapper .uix.context-menu.container table tr.loading {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 0, rgba(0, 0, 0, 0.1) 25%, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.1) 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, rgba(255, 255, 255, 0.1) 75%);
  background-repeat: repeat;
  background-size: 50px 50px;
  background-attachment: fixed;
  animation: animation-loading-background linear 0.5s infinite;
}
.uix.context-menu.wrapper .uix.context-menu.container:not(:last-child):not(:hover) {
  opacity: 0.8;
}

div.uix-markdown {
  word-wrap: break-word;
}
div.uix-markdown code {
  font-size: 0.9em;
  padding: 0.05em 0.1em;
  border: 0.1em solid rgba(0, 0, 0, 0.08);
  border-radius: 0.4em;
  color: #0c44c1;
  background-color: rgba(0, 0, 0, 0.08);
  transition: color ease-in-out 150ms, background-color ease-in-out 150ms;
}
div.uix-markdown code:hover {
  color: #052b80;
  background-color: transparent;
}

div.uix-markdown h1 {
  font-size: 2.5em;
  font-weight: 250;
}
div.uix-markdown h2 {
  font-size: 1.75em;
  font-weight: 350;
}
div.uix-markdown h1, div.uix-markdown h2 {
  text-decoration: underline;
  text-decoration-color: #848688;
  transition: text-decoration-color ease-in-out 150ms;
}
div.uix-markdown h1:hover, div.uix-markdown h2:hover {
  text-decoration-color: #a3a4a6;
}
div.uix-markdown h3 {
  font-size: 1.25em;
  font-weight: 450;
}

div.uix-markdown span.checkbox {
  box-sizing: border-box;
  display: inline-block;
  height: 1em;
  width: 1em;
  margin-bottom: -0.15em;
  border: 0.12em solid #4e9ff3;
  border-radius: 0.25em;
  transition: border-color ease-in-out 150ms, background-color ease-in-out 150ms;
}
div.uix-markdown span.checkbox::before, div.uix-markdown span.checkbox::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-radius: 0.1em;
  transition: background-color ease-in-out 150ms;
}
div.uix-markdown span.checkbox.checked {
  background-color: #4e9ff3;
}
div.uix-markdown span.checkbox.checked::before, div.uix-markdown span.checkbox.checked::after {
  background-color: #ffffff;
}
div.uix-markdown span.checkbox.checked::before {
  width: 0.12em;
  height: 0.35em;
  margin-left: 0.15em;
  margin-top: 0.32em;
  transform: rotate(-50deg);
}
div.uix-markdown span.checkbox.checked::after {
  width: 0.12em;
  height: 0.65em;
  margin-left: 0.42em;
  margin-top: 0.05em;
  transform: rotate(-140deg);
}
div.uix-markdown span.checkbox.plus {
  border-color: #60f34e;
  background-color: #60f34e;
}
div.uix-markdown span.checkbox.plus::before, div.uix-markdown span.checkbox.plus::after {
  background-color: #ffffff;
}
div.uix-markdown span.checkbox.plus::before {
  width: 0.7em;
  height: 0.12em;
  margin-left: 0.03em;
  margin-top: 0.32em;
}
div.uix-markdown span.checkbox.plus::after {
  width: 0.12em;
  height: 0.7em;
  margin-left: 0.32em;
  margin-top: 0.03em;
}
div.uix-markdown span.checkbox.minus {
  border-color: #f34e4e;
  background-color: #f34e4e;
}
div.uix-markdown span.checkbox.minus::before {
  width: 0.7em;
  height: 0.12em;
  margin-left: 0.03em;
  margin-top: 0.32em;
  background-color: #ffffff;
}
div.uix-markdown span.checkbox:hover {
  border-color: #0c7ef5;
}
div.uix-markdown span.checkbox:hover.checked {
  background-color: #0c7ef5;
}
div.uix-markdown span.checkbox:hover.checked::before, div.uix-markdown span.checkbox:hover.checked::after {
  background-color: white;
}
div.uix-markdown span.checkbox:hover.plus {
  border-color: #33f51c;
  background-color: #33f51c;
}
div.uix-markdown span.checkbox:hover.plus::before, div.uix-markdown span.checkbox:hover.plus::after {
  background-color: white;
}
div.uix-markdown span.checkbox:hover.minus {
  border-color: #f51c1c;
  background-color: #f51c1c;
}
div.uix-markdown span.checkbox:hover.minus::before {
  background-color: white;
}

form.uix.form-generator {
  padding: 5px;
  box-sizing: border-box;
  background-color: #eaecef;
}
form.uix.form-generator .form-container {
  position: relative;
  border-color: #a3a4a6;
  border-style: solid;
  border-width: 1px;
}
form.uix.form-generator .form-container .display-name, form.uix.form-generator .form-container .description {
  display: block;
  overflow-wrap: break-word;
}
form.uix.form-generator .form-container .display-name {
  color: #252627;
}
form.uix.form-generator .form-container .description {
  color: #848688;
}
form.uix.form-generator .form-container > .display-name {
  font-size: 1.2em;
}
form.uix.form-generator .form-container .input-container {
  cursor: pointer;
  padding: 1px;
  padding-bottom: 0;
  margin: 1px;
  transition: background-color ease-in-out 150ms;
}
form.uix.form-generator .form-container .input-container .input-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  align-content: flex-end;
}
form.uix.form-generator .form-container .input-container .input-wrapper > * {
  flex-basis: 100%;
}
form.uix.form-generator .form-container .input-container .input-wrapper > .display-name {
  transition: color ease-in-out 150ms;
}
form.uix.form-generator .form-container .input-container .input-wrapper > .description {
  font-size: 0.75em;
  transition: color ease-in-out 150ms;
}
form.uix.form-generator .form-container .input-container .input-wrapper:hover > .description {
  color: #252627;
}
form.uix.form-generator .form-container .input-container .input-wrapper .input-field-wrapper {
  display: inline-flex;
}
form.uix.form-generator .form-container .input-container .input-wrapper .input-field-wrapper > .form-input {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0.2em;
  border-bottom: 2px solid #a3a4a6;
  color: #252627;
  background-color: #ffffff;
  transition: border-color ease-in-out 150ms;
}
form.uix.form-generator .form-container .input-container .input-wrapper .input-field-wrapper > .form-input:hover {
  border-color: #848688;
}
form.uix.form-generator .form-container .input-container .input-wrapper .input-field-wrapper > input[type=checkbox].form-input {
  height: 1.5em;
  width: 1.5em;
}
form.uix.form-generator .form-container .input-container .input-wrapper .input-field-wrapper > textarea.form-input {
  resize: vertical;
  min-height: 4em;
  height: 4em;
}
form.uix.form-generator .form-container .input-container.required .input-wrapper .display-name::after {
  content: "*";
  margin-left: 2px;
  color: #f34e4e;
}
form.uix.form-generator .form-container .input-container.required .input-wrapper .input-field-wrapper > .form-input {
  border-color: #252627;
}
form.uix.form-generator .form-container .input-container.invalid .input-wrapper .display-name {
  text-decoration: underline;
}
form.uix.form-generator .form-container .input-container.invalid .input-wrapper .display-name, form.uix.form-generator .form-container .input-container.invalid .input-wrapper .description {
  color: #f34e4e;
}
form.uix.form-generator .form-container .input-container.invalid .input-wrapper .input-field-wrapper > .form-input {
  border-color: #f34e4e;
}
form.uix.form-generator .form-container .input-container:hover {
  background-color: #a3a4a6;
}
form.uix.form-generator .form-container .children-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
form.uix.form-generator .form-container .children-container > * {
  flex-grow: 1;
  flex-basis: 250px;
}
form.uix.form-generator .form-container .children-container > .form-container {
  flex-basis: 100%;
  margin: 20px 0;
}
form.uix.form-generator .form-container .children-container > .form-container:first-child, form.uix.form-generator .form-container .children-container > .form-container + .form-container {
  margin-top: 0;
}
form.uix.form-generator .form-container .children-container > .form-container:last-child {
  margin-bottom: 0;
}
form.uix.form-generator > .form-container {
  padding: 0;
  border: none;
}

.uix.wrapper {
  position: relative;
  width: 100%;
  vertical-align: top;
}
.uix.widget {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.uix-webpage {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: #eaecef;
}

.uix.widget.button {
  display: inline-block;
}
.uix.widget.button .wrapper {
  display: inline-block;
  user-select: none;
}
.uix.widget.button .wrapper span {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.2em 0.4em;
  border: 0.1em solid #252627;
  border-radius: 0.2em;
  cursor: pointer;
  font-size: 0.85em;
  font-weight: 550;
  color: #eaecef;
  background-color: #252627;
  transition: color ease-in-out 150ms, background-color ease-in-out 150ms;
}
.uix.widget.button .wrapper span:hover {
  color: #252627;
  background-color: #eaecef;
}
.uix.widget.button .wrapper a {
  text-decoration: none !important;
  font-weight: bold !important;
}

.uix.widget.markdown .uix-markdown span.checkbox {
  transition: all ease-in-out 150ms;
}
.uix.widget.markdown .uix-markdown span.checkbox:hover {
  transform: scale(1.2);
}

.uix.widget.site-content {
  box-sizing: border-box;
  width: 100%;
  min-width: unset;
  max-width: 1350px;
  padding: 6px;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
}

.uix.widget.vertical-divider > .wrapper {
  min-width: 2px;
  max-width: calc(100% - 2px);
  display: inline-block;
}
.uix.widget.vertical-divider > .uix.vertical-divider.resizer {
  cursor: ew-resize;
  position: absolute;
  top: 0;
  z-index: 5;
  height: 100%;
  width: 4px;
}
.uix.widget.vertical-divider.resizing > .wrapper {
  pointer-events: none;
  user-select: none;
}
.uix.widget.vertical-divider.resizing > .uix.vertical-divider.resizer {
  cursor: col-resize;
}

.uix.widget.simple-container > a {
  display: block;
  text-decoration: unset !important;
  font-weight: unset !important;
}

.uix.widget.navigation {
  position: sticky;
  top: 0;
}
.uix.widget.navigation > .uix.wrapper {
  width: unset;
  display: inline-block;
}
.uix.widget.navigation > .uix.wrapper.right-aligned {
  float: right;
}
.uix.widget.navigation > .uix.wrapper > .uix.widget {
  min-width: unset;
  min-height: unset;
}

.uix.popup.blocker {
  cursor: pointer;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.uix.popup.blocker .uix.popup.wrapper {
  cursor: auto;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: calc(100% - 84px);
  max-height: calc(100% - 84px);
  padding: 14px;
  margin-top: -28px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #ffffff;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline {
  position: relative;
  text-align: center;
  user-select: none;
  padding: 7px;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.title {
  font-size: 1.2em;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button {
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  width: 16px;
  height: 16px;
  display: inline-block;
  box-sizing: border-box;
  overflow: hidden;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: -7px;
  top: -7px;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::before, .uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::after {
  content: "";
  position: absolute;
  display: inline-block;
  transform-origin: center center;
  left: 6.4px;
  top: -2.4px;
  width: 3.2px;
  height: 20.8px;
  border-radius: 1.6px;
  background-color: #ff5b5b;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button:hover::before, .uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button:hover::after {
  background-color: #de0202;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::before, .uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::after {
  transition: background-color ease-in-out 150ms, transform ease-in-out 150ms;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::before {
  transform: rotate(45deg);
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button::after {
  transform: rotate(-45deg);
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button:hover::before {
  transform: rotate(-45deg);
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.headline .uix.popup.close-button:hover::after {
  transform: rotate(45deg);
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.container {
  overflow: auto;
}
.uix.popup.blocker .uix.popup.wrapper .uix.popup.container .uix.popup.message {
  text-align: center;
}

body.UIX-Edit-Mode .uix.widget.uix-webpage-editor {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #252627;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.widget.vertical-divider > .uix.wrapper {
  overflow: auto;
  height: 100vh;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.wrapper.webpage-wrapper {
  outline: 2px solid #848688;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.wrapper.webpage-wrapper .uix.widget.uix-webpage {
  position: relative;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.wrapper.webpage-wrapper .uix.widget.uix-webpage .uix.widget {
  cursor: pointer;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.wrapper.webpage-wrapper .uix.widget.uix-webpage .uix.widget.hover-highlight {
  outline: 2px solid #4083ca;
}
body.UIX-Edit-Mode .uix.widget.uix-webpage-editor .uix.wrapper.webpage-wrapper .uix.widget.uix-webpage .uix.widget.hover-highlight::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  background-color: #4083ca;
}

/*# sourceMappingURL=UIX.css.map */
