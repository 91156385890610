.uix{
    &.wrapper{
        position: relative;
        width: 100%;
        vertical-align: top;
    }

    &.widget{
        position: relative;
        min-width: 100%;
        min-height: 100%;
    }
}

.uix-webpage{
    position: absolute;

    min-width: 100%;
    min-height: 100%;

    background-color: $widget-webpage-background-color;
}